/*-----------------------------------*\
    Elements
\*-----------------------------------*/

/*
 * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
 html {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    background-color: $white;
    transition: background-color $animation 0.4s;
}

.aa-site-wrapper {
    display: flex;
    flex-direction: column;
}

header, main, footer {
    width: 100%;
}

main {
    overflow: hidden;
}

// Force the footer to bottom of the page, even if the content does not fill the viewport height
> footer {
    margin-top: auto;
}

a {
    text-decoration: $link-text-decoration;
    text-decoration-skip-ink: auto;

    color: $link-color;

    transition: all $speed $animation;
}

a:visited {
}

a:hover,
a:focus,
a:active {
    color: $link-color-hover;
}

a:focus {
    outline: none;
}

a:hover,
a:active {
    outline: 0;
}

hr {
    margin-top: 0;
    margin-bottom: 30px;

    border: 0;
    border-top: 4px solid $light;

    @include bp(lg) {
        margin-bottom: 40px;
    }
}

img, video, iframe {
    max-width: 100%;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}
/*-----------------------------------*\
    Article cards
\*-----------------------------------*/

$container-width: $full-site-width;
$columns: 4;
$gap: 0;
$column-width: ($container-width / $columns) - $gap;

.aa-article-cards {
	padding-left: $padding;
	padding-right: $padding;

	@include bp(en) {
		padding-left: 0;
		padding-right: 0;
	}

	.aa-inner {
		flex-direction: column;
		max-width: 100%;
	}
}

.aa-article-cards__header {
	max-width: $full-site-width;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 50px;
	padding-left: $padding;
	padding-right: $padding;

	@include bp(sm) {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}

	@include bp(en) {
		padding-left: 0;
		padding-right: 0;
	}

	.aa-article-cards__title {
		margin-bottom: 15px;


		@include bp(sm) {
			margin-bottom: 0;
		}

		h2 {
			font-weight: $weight-300;
		}
	}
}

.aa-article-cards__body {
	padding-left: $padding;
	padding-right: $padding;

	@include bp(lg) {
		padding-left: 0;
		padding-right: 0;
		display: flex;
	}

	@supports (display: grid) {
		@include bp(lg) {
			grid-template-columns: repeat(3, 1fr);
			gap: 15px;
			max-width: 100%;
		}
	}
}

.aa-article-cards-container {
	max-width: $full-site-width;
	width: 100%;
	margin-right: auto;
	margin-left: auto;

	@include bp(lg) {
		display: flex;
		gap: 15px;
	}
}

.aa-article-card {
	text-decoration: none;
	max-width: 450px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	align-self: start;
	max-height: 560px;
	background-color: $white;

	@include bp(lg) {
		max-width: 100%;
		height: 560px;
	}

	&:hover {
		@include bp(lg) {
			.aa-article-card__body {
				transform: translateY(-75px);
			}
			.aa-card-button-container {
				height: 106px;
			}
		}
	}
}

.aa-article-card__header {
	position: relative;
	height: 370px;

	.aa-article-card__date {
		position: absolute;
		z-index: 12;
		display: flex;
		justify-content: center;
		align-items: center;
		right: 15px;
		bottom: 15px;
		width: 91px;
		height: 91px;
		background-color: $secondary;
		color: $white;

		font-weight: $weight-400;
		font-size: 18px;
		line-height: 22px;
	}
}

.aa-article-card__body {
	margin-top: auto;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 25px;
	padding-top: 30px;
	overflow: hidden;
	transition: all ease-in-out 0.2s;
	text-decoration: none;
	background-color: $white;

	.aa-article-card__title {
		margin-top: 0;
		margin-bottom: 25px;
		color: $primary;
		max-width: 75%;
	}

	.aa-article-card__text {
		display: block;
		color: $dark;
		margin-bottom: 30px;

		@include bp(lg) {
			margin-bottom: 0;
		}
	}

	.aa-card-button-container {

		@include bp(lg) {
			text-decoration: none;
			height: 0;
			transition: all 0.2s ease-in-out;

			.aa-button {
				display: inline-block;
				margin-top: 30px;
				margin-bottom: 30px;
				text-decoration: none;
			}
		}
	}
}

.aa-bg-light .aa-article-card {
	text-decoration: none;
}
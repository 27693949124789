/*-----------------------------------*\
    Header
\*-----------------------------------*/

.aa-site-header {
    position: absolute;
    max-width: 100%;
    width: 100%;
    height: 92px;

    padding-left: $padding;
    padding-right: $padding;

    z-index: 20;

    @include bp(en) {
        padding-left: 65px;
        padding-right: 65px;
    }
}

.aa-site-header-lp {
    position: relative;
}

.js-nav-open .aa-site-header {
    background-color: $white;
}

.js-nav-open .aa-site-header.aa-site-header--scrolled {
    background-color: $white;
}

.aa-site-header__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    width: 100%;
    height: inherit;

    @include bp(xl) {
        padding-top: 40px;
    }

    .aa-branding {
        @include bp(lg) {
            margin-right: 85px; //to make container 200px as some and center menu
        }
    }
}

.aa-branding {
    width: 100%;
    max-width: 115px;
}

.aa-navigation-some-wrapper {
    width: 200px;
}

.hs-menu-item.hs-menu-depth-1 {

    @include bp(lg) {
        position: relative;

        &:after {
            content:"/";
            top: 3px;
            right: 11px;
            position: absolute;
            color: $secondary;
            font-size: 14px;
            line-height: 17px;
        }

        &:last-of-type {
            &:after {
                content:"";
            }
        }
    }
}

.aa-navigation-some-container {
    display: none;

    @include bp(lg) {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 30px;
        top: 350px;

        svg {
            margin-right: 1px;

            rect {
                fill: none;
            }
        }
    }
}

.aa-site-header--scrolled {
    .aa-navigation-some-container {
        svg {
            rect {
                fill: #E3E9EC;
            }
        }
    }
}


.aa-navigation-some-container-mobile {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    svg {
        margin-right: 1px;
    }
}

.aa-site-header.aa-site-header--scrolled {
    .aa-navigation-some-container {
        flex-direction: row;
        position: initial;
    }
}

.aa-site-header.aa-site-header--scrolled {
    position: fixed;
    background: rgba($white, 0.8);

    .aa-site-header__inner {
        @include bp(xl) {
            padding-top: 0;
        }
    }
}

.aa-site-header--light {
    .hs-menu-wrapper>ul>li>a {
        color: $white;
    }

    .aa-navigation--primary .hs-menu-wrapper>ul>li>a:hover {
        color: rgba($white, 0.9);
    }

    .logo-light-not-scrolled {
        display: block;
    }

    .logo-light-scrolled {
        display: none;
    }

    .aa-hamburger {
        span,
        span:before,
        span:after {
            display: block;

            background-color: $white;

            transition-property: all;
            transition-duration: .1s;
        }
    }

    svg {
        path {
            fill: $white;
        }
        .logo-square {
            fill: $secondary;
        }
    }
}

.aa-site-header--light.aa-site-header--scrolled {
    .logo-light-not-scrolled {
        display: none;
    }

    .logo-light-scrolled {
        display: block;
    }

    .hs-menu-wrapper>ul>li>a {
        color: $black;
    }

    .aa-navigation--primary .hs-menu-wrapper>ul>li>a:hover {
        color: rgba($black, 0.9);
    }

    .aa-hamburger {
        span,
        span:before,
        span:after {
            display: block;

            background-color: $black;

            transition-property: all;
            transition-duration: .1s;
        }
    }

    svg {
        path {
            fill: #4E8B79;
        }
    }
}
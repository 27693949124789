/*-----------------------------------*\
  AA - Hero
\*-----------------------------------*/

.aa-hero {
    position: relative;

    .aa-inner {
        position: relative;
        z-index: 10;
        padding-top: 160px;
        padding-bottom: 410px;

        @include bp(lg) {
            padding-top: 235px;
        }
    }

    .hero-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        clip-path: polygon(0px 0px, 100% 0px, 100% 90%, 0px 100%);

        .overlay {
            height: 100%;
            width: 100%;

            &:after,
            &:before {
                height: 100%;
                width: 100%;
            }
        }
    }

    .aa-content-width--small {
        max-width: calc(#{$full-site-width} / 12 * 6);
    }

    .aa-content-width--normal {
        max-width: calc(#{$full-site-width} / 12 * 8);
    }

    .aa-content-width--wide {
        max-width: calc(#{$full-site-width} / 12 * 10);
    }

    .aa-bg-graphic {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        width: 280px;
    }
}

.aa-hero-nav-container {
    position: relative;
    width: 100%;
    height: 0;

    .aa-hero-nav-arrow {
        position: absolute;
        z-index: 12;
        top: -60px;
        right: 5px;

        @include bp(hg) {
            right: 25px;
        }

        @include bp(en) {
            right: 50px;
        }
    }
}

.aa-hero__header,
.aa-hero__body {
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }
}

.aa-hero__body {
    max-width: calc(#{$full-site-width} / 12 * 4);
}

//Nav animation

.nav-arrow-animation {
    -webkit-animation: nav-arrow-animation 4s ease infinite;
    -moz-animation: nav-arrow-animation 4s ease infinite;
    animation: nav-arrow-animation 4s ease infinite;
}

@-webkit-keyframes nav-arrow-animation {
0%{transform: translateY(-0%)}
50%{transform: translateY(-30%)}
100%{transform: translateY(-0%)}
}

@-moz-keyframes nav-arrow-animation {
0%{transform: translateY(-0%)}
50%{transform: translateY(-30%)}
100%{transform: translateY(-0%)}
}

@keyframes nav-arrow-animation {
0%{transform: translateY(-0%)}
50%{transform: translateY(-30%)}
100%{transform: translateY(-0%)}
}


//modifiers

.aa-hero--no-pb {
    .aa-inner {
        position: relative;
        z-index: 10;
        padding-top: 160px;
        padding-bottom: 64px;

        @include bp(lg) {
            padding-top: 260px;
            padding-bottom: 120px;
        }
    }
}
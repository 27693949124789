/*-----------------------------------*\
  AA - Map
\*-----------------------------------*/

.aa-map {
	padding-right: 0;
	padding-left: 0;

	@include bp(sm) {
		padding-left: 20px;
	}

	@include bp(xl) {
		padding-left: 0;
	}

	.aa-inner {
		max-width: 2100px;
		margin-left: auto;
		margin-right: auto;
	}

	.aa-content-container-desktop {
		display: flex;
		width: 100%;
	}
}

.aa-map__header {
	max-width: 360px;
	width: 100%;
	position: relative;
	margin-left: auto;
	margin-top: 120px;
	padding-right: 40px;
}

.aa-map__body {
	max-width: 70%;
	width: 100%;

	img {
		width: 100%;
	}

	.map-container {
		position: relative;
		width: 100%;
		height: auto;
	}
}

.map-marker {
	position: absolute;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	cursor: pointer;
	background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Crect width='16' height='16' fill='url(%23pattern0)'/%3E%3Cdefs%3E%3Cpattern id='pattern0' patternContentUnits='objectBoundingBox' width='1' height='1'%3E%3Cuse xlink:href='%23image0_4643_6133' transform='scale(0.03125)'/%3E%3C/pattern%3E%3Cimage id='image0_4643_6133' width='32' height='32' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAA9FJREFUWEellluIHEUUhv9TvboxsOMNwQeV4GVRjIkZI17eFmJwjXd9EsU86INK2O7TPXFxjbSQDLo7Xe0ucQVBvICv0RgJSJB9CShKJoI3NIokCJLowzoSswx2HymZldmemu6e3Xrs+v9zvlNVXXUIA4zp6enLHcd5gIjuB3ANgCs69l8B/CwiHzmO86HruqfLhqUyQq31JSKym4gmAKwr8LQBvJMkyZ5arXamKH4hQBRFW4joIIAri4Jl5n8D8CAzf5HnywWI43hcRA6UqLpfjnNKqYdc1/2kn6AvQKPRuF4p9TmACwesPCv/C8AdzPytLY4VIAxDNTIy0iSizTnJTeCfOvPXAhjJ0TZbrdatYRimWY0VoNFoPKGUeq9PwJNKqecXFxc/CMPQHDjMzc0NJ0nysIi8AuAqmy9N08eDIHi/FIDWuglgiyXQ8TRNtwdB8IctSb1ev2x4ePiIbeWI6JjneVsLAeI43iAiv1gS/K2UusF13VN5Z6Lj/w7ABRmdKKU2ZP09WxBF0U4ietuSZD8z7ypzILXWrwN4NqsVkSd931+xtTaAKSLaazHv8H3/cBmAKIruJaJDFu0UM9e7v/cAaK1fA2BuvBVDRKq+7x8vAxDHcVVEjlm0s8zsFgHsA/CCBWCb7/uflgTYJiJHeg4c0T7P817MBYii6GkiejNrTtN0bxAEe8oAaK2tRSilnnJd960igM1E9JUl0e/tdvu6ycnJP/Mg5ufnL15aWjoB4FKLbhMzf50LAIC01j8CMLdbdhxqtVqPLl9A2ckwDM+vVCrm7dhh8Z5g5tGebbFVo7V+GcBLtjkROTo0NPTMxMTEN93zMzMzmxzHeQPAnTYfEYWe55m4K4b1Ko6iaJSIfshZaiGiZpqm/z0wRLQRQDVPD2CUmZffjv+lfV9DrbX558fLHLoSmo+Z+T7ryvQzNxqNW5RSX5oCSyTIk0iaprcFQWBi9YyihuSgiJj+by3jADM/0i9ALsDs7OzGJEnMy3jeKgnaRHSz53nfrwrAmKIoepWIdq8SoM7MU3newv0Nw3B9pVIxl8fVA0KcTJLkxlqtdnZNAMastb4bgPkrCoE7yczbP57XjC5DlQ1otmI/ET1XchXmmLnnRR34L+g2hGG4rlKpmB7/pgII0w1tZeZzZWBLr4AJ1rluPwOwvk/ws47j3J69ptd8BroDaK0fA9DT3XY0O5n53TKVD3wGMhC2nq90z9gda6AtWDYuLCwMNZvNw0R0l/lmup9qtXrP2NjYP4NUb7SrAjDGOI4vMolFxPxy2z3PWxw0udH/CySdVjByKeblAAAAAElFTkSuQmCC'/%3E%3C/defs%3E%3C/svg%3E%0A");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	transition: all ease-in-out 0.2s;
}

.map-marker.is-active {
	background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Crect width='24' height='24' fill='url(%23pattern0)'/%3E%3Cdefs%3E%3Cpattern id='pattern0' patternContentUnits='objectBoundingBox' width='1' height='1'%3E%3Cuse xlink:href='%23image0_4643_6137' transform='scale(0.03125)'/%3E%3C/pattern%3E%3Cimage id='image0_4643_6137' width='32' height='32' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACvUlEQVRYCbVWS2sUQRBuRaerd6NR8XUSDyKKL1T0Dyj4wKuISjSS7Zo14MGrHoxoQG+KevDoD/Dg85CreNFERBCCQYwKMdmu2cQlicTDjkycyO5S3T07wYFmpr/6HtXN0LQQbTyV3uJGgzKMNLwghGFCmE7HcIIltYlScUMbltmoP3tWriENtwjhFyHEnjEXITwcL3esz+buYVXKhb2E8M0TyjU1RmV10GPvLle1PJZx1VwDCTYbaXnEnWKpEgbbCGEqx8pbm6lFOthhieHhuE8sNajee8JrhPLd3wE1N1cOJZ58GoOShi6H4WgUqlPxSREsSEcuCWlCdZoQvtp0RsPZBb73na6qdSvjBK/hirU2gx8XOtY5dm7QpmvCJ8uw2bKKmSrCpiYyM0n1s4xHPYtekIZuRhwTqntMHgtRqB6wHiGcYwWNICFc5cSVUB5v5Lm+jZYnOA9CuOLSzdcoVHc4cXIgecUpwWBhH+eReHs9CKGfF8MhrzglkIbDrAfCTa+H0aBZsYYbXvFCA5ZFRCH0eD0mwuV72AYQKlUUnT6DqYudqwmBOI8qBrt8ehELsYQQRjgDQvW08QBqNUtqEarnvBY+tfKtcxPCdYtJbBBeVcvBzlZxtRTsJoTXNl2EcK1VY50bLbfajFK8TgiDpOHR/EA55ONXeuQWayBXMAgvPabMUW25rITqGZfhxEypsJ8QkpVmD+K5ddKFA84wW5FQPVl8A/Kxzd+LJz8bIfxeRBNzVAq2e4NcBEJ5exEN9Lu8M9XGUBQihM85mhgd7xLFTCE+UhTKo23+kPXcl1FbM4TqfvZdUHdtPrnxL90CCOFDhiY+fr8sVO4glzA9bmccTUxzx7TLs+2aQXXG0cD5tg3zCPg7X/Y7Y57MJk3cJ5aRhoF/O6FhIMGaSP97MtktVhHCW0J4k3znzfsDVroT3t3srAsAAAAASUVORK5CYII='/%3E%3C/defs%3E%3C/svg%3E%0A");
	transition: all ease-in-out 0.2s;
	width: 24px;
	height: 24px;

	.info-container {
		display: block;
	}
}

.marker-info-box {
	margin-left: auto;
	display: none;
	background: #FFFFFF;
	box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
	animation: fadeIn 0.5s ease-in forwards;
}

.marker-info-box.is-active {
	display: block;
}

.marker-info-box__header {
	img {
		width: 100%;
	}
}

.marker-info-box__body {
	padding: 25px 40px;
}

.aa-map .aa-content-container-mobile {
	width: 100%;

	.select-container {
		display: inline-block;
		position: relative;
		z-index: 10;
		width: 100%;

		label {
			display: block;
			margin-bottom: 10px;
		}
	}

	.map-locations {
		-moz-appearance:none; /* Firefox */
		-webkit-appearance:none; /* Safari and Chrome */
		appearance:none;

		border: none;
		background: #FFFFFF;
		box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
		max-width: 100%;
		width: 100%;
		height: 48px;
		padding: 15px 45px;
		margin-bottom: 30px;

		background-size: 15px;
		background-repeat: no-repeat;
		background-position: calc(100% - 40px) center;
		background-image: url("data:image/svg+xml,%3Csvg width='15px' height='10px' viewBox='0 0 15 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.75211 0L7.45578 5.69125L13.1595 0L14.9116 1.75211L7.45578 9.20789L0 1.75211L1.75211 0Z' fill='black'/%3E%3C/svg%3E%0A");
	}

	.aa-title,
	.select-container {
		padding-left: 20px;
		padding-right: 20px;

		@include bp(lg) {
			padding-right: 0;
			padding-left: 0;
		}
	}

	.marker-info-box {
		position: relative;
		z-index: 12;
		margin-left: 20px;
		margin-right: 20px;
		padding-right: 0;
		padding-left: 0;

		@include bp(md) {
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.marker-info-box__image__mobile {
	position: relative;
	z-index: 2;
	display: none;
	animation: fadeIn 0.5s ease-in forwards;
	margin-top: -150px;

	img {
		width: 100%;
	}
}

.marker-info-box__image__mobile.is-active {
	display: block;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
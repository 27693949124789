/*-----------------------------------*\
    AA - Features
\*-----------------------------------*/

.aa-features {
	.aa-inner {
		flex-direction: column;
	}
}

.aa-features__header {
	max-width: calc(#{$full-site-width} / 12 * 7);
	margin-bottom: 40px;
}

.aa-features__body {
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 20px;

	@include bp(lg) {
		grid-template-columns: repeat(3, 1fr);
		max-width: 100%;
		column-gap: 45px;
		row-gap: 45px;
	}
}

.aa-feature__image {
	margin-bottom: 35px;

	@include bp(lg) {
		margin-bottom: 45px;
	}

	img {
		width: 100%;
		height: auto;
	}
}

//Layout modifiers

.aa-features--2 {
	.aa-features__body {
		@include bp(lg) {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}

.aa-features--3 {
	.aa-features__body {
		@include bp(lg) {
			grid-template-columns: repeat(3, 1fr);
		}
	}
}

.aa-features--4 {
	.aa-features__body {
		@include bp(lg) {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}
/*-----------------------------------*\
    AA - Focus box
\*-----------------------------------*/

.aa-focus-box {
    position: relative;

    &.aa-bg-overlay-gradient--primary:before {
        background: linear-gradient(90deg, rgba($primary-rgb, 0.9) 25.55%, rgba($primary-rgb, 0.5) 100%);
    }
    &.aa-bg-overlay-gradient--secondary:before {
        background: linear-gradient(90deg, rgba($secondary-rgb, 0.9) 25.55%, rgba($secondary-rgb, 0.5) 100%);
    }
    &.aa-bg-overlay-gradient--complementary:before {
        background: linear-gradient(90deg, rgba($complementary-rgb, 0.9) 25.55%, rgba($complementary-rgb, 0.5) 100%);
    }
    &.aa-bg-overlay-gradient--light:before {
        background: linear-gradient(90deg, rgba($light-rgb, 0.9) 25.55%, rgba($light-rgb, 0.5) 100%);
    }
    &.aa-bg-overlay-gradient--dark:before {
        background: linear-gradient(90deg, rgba($dark-rgb, 0.9) 25.55%, rgba($dark-rgb, 0.5) 100%);
    }
    &.aa-bg-overlay-gradient--white:before {
        background: linear-gradient(90deg, rgba($white, 0.9) 25.55%, rgba($white, 0.5) 100%);
    }
}

.aa-focus-box__header,
.aa-focus-box__body {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}
/*-----------------------------------*\
    Quote
\*-----------------------------------*/

.aa-quote {
    @extend %burj-bottom;

    @include bp(md) {
        padding-top: 100px;
    }

    .aa-inner {
        position: relative;
        max-width: calc(#{$full-site-width} / 12 * 10);
        background-color: $light;
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
    }

    .aa-bg-graphic {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        @include bp(md) {
            max-width: 350px;
            bottom: 65px;
        }

        @include bp(lg) {
            bottom: 0;
            height: 325px;
            max-width: 700px;
        }
    }

    .aa-content {
        display: flex;
        position: relative;
        flex-direction: column-reverse;
        z-index: 2;
        max-width: calc(#{$full-site-width} / 12 * 8);
        margin-left: auto;
        margin-right: auto;
        margin-top: -50px;

        @include bp(md) {
            margin-top: -60px;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
        }
        @include bp(lg){
            margin-top: -100px;
        }
    }
}

.aa-quote__header {
    width: 100%;

    @include bp(lg) {
        max-width: calc(#{$full-site-width} / 12 * 4.5);
    }
}

.aa-quote__body {
    @extend %empire-top;
    @extend %empire-bottom;

    align-self: end;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;

    @include bp(md) {
        max-width: calc(#{$full-site-width} / 12 * 3);
    }

    @include bp(xl) {
        padding-left: 0;
        padding-right: 0;
    }

    blockquote {
        margin-top: 20px;
        font-size: 16px;
        line-height: 22px;
        font-weight: $weight-400;
        hyphens: manual;

        @include bp(md) {
            margin-top: 40px;
        }
    }

    .aa-quote__name {
        margin-bottom: 0;
        font-weight: $weight-400;
        font-size: 18px;
        line-height: 22px;
        color: $primary;
    }

    .aa-quote__title {
        margin-bottom: 0;
        font-weight: $weight-400;
        font-size: 12px;
        line-height: 16px;
    }
}
/*-----------------------------------*\
    System Pages
\*-----------------------------------*/

.aa-system {
    @extend %burj-top;
    @extend %burj-bottom;

    @include bp(lg) {
        padding-top: 240px;
    }

    .hs-button {
        @extend .aa-button;
        @extend .aa-button--1;

        margin-top: 20px;
    }

    .aa-inner {
        @include bp(lg) {
            max-width: calc(#{$full-site-width} / 12 * 6);
        }
    }
}

.aa-system__body,
.aa-system__footer {
    margin-top: 30px;
}

.aa-system .backup-unsubscribe {
    form {
        margin-top: 30px;
    }
}

.aa-system #email-prefs-form {
    p.header {
        margin-top: 40px;
        margin-bottom: 15px;

        font-weight: $weight-700;
    }

    .hs-button {
        margin-top: 30px;
    }

    .item {
        margin-bottom: 20px;

        input {
            margin-right: 5px;
        }

        .checkbox-row {
            margin-bottom: 5px;

            > span {
                display: flex;
                align-items: center;
            }

            > span > span {
                @extend .aa-subheading;

                margin-bottom: 0;
            }
        }

        p {
            font-style: italic;
        }
    }
}

.aa-system .hs_cos_wrapper_type_email_subscriptions_confirmation {
    display: flex;
    flex-direction: column;

    .page-header {
        order: 2;

        font-style: italic;

        h1, h2 {
            font-style: normal;
        }

        h1 {
            @extend .h2;

            &:empty {
                display: none;
            }
        }

        h2 {
            @extend .h3;
        }

        br {
            display: none;
        }
    }

    .success {
        @extend .h1;

        order: 1;
        margin-bottom: 40px;

        color: $primary;
        font-weight: $weight-700;
    }
}

.aa-system .hs_cos_wrapper_type_password_prompt {
    #hs-pwd-widget-password {
        @extend .hs-input;

        height: 50px !important; // Override default inline styling
        margin-top: 5px !important; // Override default inline styling
    }
}
/*-----------------------------------*\
    Card slider
\*-----------------------------------*/

.aa-card-slider {
	overflow: hidden;

	.aa-faq-card {
		min-height: 450px;
		display: flex;
		align-items: center;
		justify-content: center;

		.aa-faq-card__body {
			flex: 0;
		}
	}

	.splide__slide {
		&:nth-child(3n+1) {
			.aa-faq-card {
				background-image: url('https://f.hubspotusercontent20.net/hubfs/9441822/avidly-theme/assets/images/GRAPHIC-03%20(2).png');
				background-position: bottom left;
				background-repeat: no-repeat;
				background-size: 280px;
			}
		}
		&:nth-child(3n+2) {
			.aa-faq-card {
				background-image: url('https://f.hubspotusercontent20.net/hubfs/9441822/avidly-theme/assets/images/GRAPHIC-03%20(4).png');
				background-position: top right;
				background-repeat: no-repeat;
				background-size: 340px;
			}
		}
		&:nth-child(3n+3) {
			.aa-faq-card {
				background-image: url('https://f.hubspotusercontent20.net/hubfs/9441822/avidly-theme/assets/images/GRAPHIC-03%20(3).png');
				background-position: bottom right;
				background-repeat: no-repeat;
				background-size: 280px;
			}
		}
	}
}

.aa-card-slider__header {
	@extend %eiffel-bottom;

	max-width: calc(#{$full-site-width} / 12 * 5);
}

.aa-card-slider__body {
	max-width: $full-site-width;
	margin-left: auto;

	.splide__slide {
		max-width: 450px;
		opacity: 0.3;
	}

	.splide__slide.is-visible {
		opacity: 1;
	}

	.splide__track {
		position: relative;
		overflow: visible;
	}

	button:disabled {
		svg {
			fill: $dark;
		}
	}

	.splide__arrows {
		position: absolute;
		top: -25px;
		right: 50px;

		@include bp(xl) {
			top: -70px;
		}
	}

	.splide__arrow {
		background: transparent;
		left: 0;
		right: 0;
		opacity: 1;
		width: 47px;

		svg {
			width: 47px;
			fill: $secondary;
		}
	}

	.splide__arrow--prev {
		left: -60px;

		svg {
			transform: scaleX(1);
		}
	}
}
/*-----------------------------------*\
    Faq Cards
\*-----------------------------------*/

.aa-faq-cards {
	position: relative;

	.bg-graphic-bottom-left,
	.bg-graphic-top-right {
		position: absolute;
		z-index: 2;
		height: auto;
	}
	.bg-graphic-bottom-left {
		bottom: 0;
		left: 0;
	}
	.bg-graphic-top-right {
		top: 150px;
		right: 0;
	}
    .aa-inner {
		position: relative;
		z-index: 6;
        max-width: $full-site-width;
        margin-left: auto;
		margin-right: auto;
		flex-direction: column;
    }
}

.aa-faq-cards__header {
	max-width: calc(#{$full-site-width} / 12 * 5);
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
	text-align: center;

    @include bp(lg) {
        margin-bottom: 60px;
	}
}

.aa-faq-cards__body {
    width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 16px;

	@include bp(md) {
		grid-template-columns: repeat(3, 1fr);
        max-width: 100%;
		column-gap: 45px;
		row-gap: 45px;
	}
}

.aa-faq-card {
    display: flex;
    flex-direction: column;
    width: 100%;
	background-color: $white;
	box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
	padding: 30px;
	text-decoration: none;
	color: $dark;

    transition: all $speed $animation;

	@include bp(lg) {
		padding: 60px;
	}

    &:hover {
        background-color: $white;
        box-shadow: 0 4px 32px 0 rgba($black, .2);
		color: $dark;
        text-decoration: none;
    }
}

.aa-faq-card.aa-card-nolink {
	transition: none;

	&:hover {
		background-color: $white;
		box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
        text-decoration: none;
    }
}

.aa-faq-card__header {
    img {
		margin-left: auto;
		margin-right: auto;
    }
}

.aa-faq-card__body {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
	padding-top: 20px;
	text-align: center;

    // background-color: $white;

    transition: all $speed $animation;
}

// Modifiers for layout

.aa-faq-cards--2 {
	.aa-cards__body {
		@include bp(sm) {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}

.aa-faq-cards--3 {
	.aa-cards__body {
		@include bp(md) {
			grid-template-columns: repeat(3, 1fr);
		}
	}
}

.aa-faq-cards--4 {

    .aa-inner {
        max-width: $full-site-width;
    }

	.aa-faq-cards__body {
		column-gap: 16px;
		row-gap: 16px;

		@include bp(sm) {
			grid-template-columns: repeat(2, 1fr);
		}
		@include bp(lg) {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}

.aa-faq-cards--skewed {
	.skewed-shadow {
		position: relative;
		z-index: 6;
		filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.1));

		&:after {
			content: "";
			width: 49px;
			height: 49px;
			position: absolute;
			z-index: 2;
			right: -14px;
			top: -14px;
			background-color: #575757;
		}

		&:nth-of-type(2) {
			&:after {
				background-color: #2C574B;
			}
		}

		&:nth-of-type(3) {
			&:after {
				background-color: #80A1B2;
			}
		}
	}
	.aa-faq-card {
		position: relative;
		z-index: 6;
		background-color: $white;
		box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
		clip-path: polygon(0px 0px, 100% 0px, 100% 88%, 0px 100%);
	}
}
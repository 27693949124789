/*-----------------------------------*\
  Tags
\*-----------------------------------*/

.aa-tags__inner {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    max-width: $full-site-width;
    padding-left: $padding;
    padding-right: $padding;

    @include bp(md) {
        margin-left: auto;
        margin-right: auto;
    }

    @include bp(hg) {
        padding-left: 0;
        padding-right: 0;
    }
}

.aa-tag {
    display: flex;
    padding: 7px 20px;
    margin-bottom: 15px;

    font-size: 12px;
    line-height: 16px;

    text-decoration: none;
    color: $primary;
    background-color: transparent;
    border: 1px solid $primary;
    border-radius: 100px;

    &:not(:last-of-type) {
        margin-right: 15px;
    }

    &:hover {
        text-decoration: none;
        border: 1px solid $secondary;
        color: $white;
        background-color: $secondary;
    }
}

.aa-tag--current {
    background-color: $secondary;
    border: 1px solid $secondary;
    color: $white;
}
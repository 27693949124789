/*-----------------------------------*\
  AA - Tech Specs
\*-----------------------------------*/

.aa-tech-specs {

}

.aa-tech-specs .aa-inner {
	flex-direction: column;
    max-width: $full-site-width;
    margin-left: auto;
    margin-right: auto;
}

.aa-tech-specs__header {
	padding-bottom: 35px;
	border-bottom: 1.5px solid $light;
}

.aa-tech-specs__body {
	width: 100%;
	padding-top: 35px;

	@include bp(lg) {
		display: flex;
		gap: 45px;
	}
}

.aa-tech-specs__col {
	width: 100%;

	&:last-of-type {
		margin-top: 45px;

		@include bp(lg) {
			margin-top: 0;
			max-width: 600px;
		}
	}
}

.aa-tech-specs-title {
	font-weight: 700;
	font-size: 20px;
	line-height: 30px;
	color: $black;
	margin-bottom: 10px;
}

.aa-tech-specs-item {
	display: flex;

	padding-left: 25px;

	color: $black;

	@include bp(lg) {
		padding-left: 45px;
	}

	&:nth-last-of-type(odd) {
		background-color: $light;
	}

	.aa-tech-specs-item__title {
		font-weight: 400;
		font-size: 12px;
		line-height: 22px;
		margin-right: auto;
		padding-right: 20px;

		@include bp(lg) {
			padding-right: 0;
		}
	}

	.aa-tech-specs-item__title {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.aa-tech-specs-item__number {
		display: flex;
		align-items: center;
		justify-content: center;

		text-align: center;
		max-width: 120px;
		width: 100%;

		font-weight: 900;
		font-size: 16px;
		line-height: 22px;
	}

	.aa-tech-specs-item__all,
	.aa-tech-specs-item__40-con,
	.aa-tech-specs-item__20-con {
		display: flex;
		align-items: center;
		justify-content: center;

		max-width: 170px;
		width: 100%;
		border-left: 1px solid rgba(#80A1B2, 0.3);
		text-align: center;

		font-weight: 900;
		font-size: 16px;
		line-height: 22px;
	}

	.aa-tech-specs-item__all {
		padding-top: 20px;
		border-left: none;
		max-width: 100%;

		@include bp(lg) {
			max-width: 340px;
			padding-top: 0;
			border-left: 1px solid rgba(#80A1B2, 0.3);
		}
	}
}

.aa-tech-specs__col--container {

	.aa-tech-specs__col-title-container {
		display: flex;
	}

	.aa-tech-specs-title {
		margin-right: auto;
	}

	.aa-tech-specs-title__40-con,
	.aa-tech-specs-title__20-con {
		display: flex;
		align-items: center;
		justify-content: center;

		max-width: 170px;
		width: 100%;
		border-left: 1px solid rgba(#80A1B2, 0.3);
		text-align: center;

		font-weight: 400;
		font-size: 12px;
		line-height: 22px;
	}
}

.aa-tech-specs-image {
	position: relative;
	margin-top: 40px;
	margin-right: 30px;

	@include bp(lg) {
		margin-right: 0;
	}

	img {
		width: 100%;
	}
}

.aa-tech-specs-image__info {
	position: absolute;
	max-width: 320px;
	right: -30px;
	bottom: -30px;

	padding-top: 55px;
	padding-left: 40px;
	padding-right: 30px;
	padding-bottom: 20px;

	background-color: $secondary;
	color: $white;
	text-align: right;

	font-weight: 400;
	font-size: 13px;
	line-height: 16px;

	a {
		color: $white;

		&:hover {
			color: rgba($white, 0.7);
		}
	}
}

.info {
	position: relative;
	cursor: pointer;
	width: 13px;
	height: 13px;

	svg {
		position: absolute;
		top: -10px;
		right: 0;
		width: 13px;
		height: 13px;
	}

	&:hover {
		.info-box {
			display: block;
		}
	}

	.info-box {
		position: absolute;
		z-index: 12;
		right: 0;
		top: -15px;
		width: 280px;
		padding: 20px;
		display: none;
		transform: translateY(-100%) translateX(100%);

		background: #FFFFFF;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
		border-radius: 6px;

		text-align: left;
		font-weight: 400;
		font-size: 11px;
		line-height: 14px;
	}
}

//mobile version

.aa-tech-specs__col--container--mobile {
	.accordion-item-container {
		&:nth-of-type(odd) {
			background-color: $light;
		}
	}
}

.aa-tech-specs-container--mobile {
	.accordion-item-container {
		&:nth-of-type(odd) {
			background-color: $light;
		}
	}
}

.aa-tech-specs__col--container--mobile,
.aa-tech-specs-container--mobile {
	.aa-accordion-readmore {
		border-radius: 50%;
	}

	.aa-accordion-item__header {
		padding-bottom: 60px;
	}

	.aa-tech-specs-item__title {
		max-width: 80%;
		color: $black;
	}

	.info-container {
		display: flex;

		.aa-tech-specs-title__20-con {
			border-left: none;
		}

		.aa-tech-specs-title__20-con,
		.aa-tech-specs-title__40-con {
			max-width: 50%;
			padding-bottom: 20px;
		}

		.info-box {
			transform: translateY(-100%) translateX(40px);
		}

		.aa-tech-specs-title__20-con .info-box {
			transform: translateY(-100%) translateX(50%);
		}
	}

	.aa-tech-specs-item {
		padding-left: 0;
		border-top: 1px solid rgba(#80A1B2, 0.3);
	}

	.aa-tech-specs-item:nth-last-of-type(odd) {
		background-color: transparent;
	}

	.aa-tech-specs-item__20-con {
		border-left: none;
	}

	.aa-tech-specs-item__20-con,
	.aa-tech-specs-item__40-con {
		max-width: 50%;
		padding-top: 20px;
	}
}

.aa-tech-specs-container--mobile {
	.aa-accordion-item__header {
		padding-bottom: 30px;
	}

	.aa-tech-specs-item__number {
		border-top: 1px solid rgba(#80A1B2, 0.3);
		padding-top: 30px;

		color: $black;
		font-weight: 900;
		font-size: 16px;
		line-height: 22px;
	}
}

//aa-tech-specs V2

.aa-tech-specs--v2 {
    // .aa-tech-specs__col {
    //     &:last-of-type {
    //         @include bp(lg) {
    //             max-width: 50%;
    //         }
    //     }
    // }

    .aa-tech-specs-item {
        &:nth-of-type(2n + 1) {
            background-color: $light;
        }

        &:nth-of-type(2n) {
            background-color: $white;
        }
    }

    .aa-tech-specs-container--mobile .aa-accordion-item__header {
        padding-bottom: 20px;
    }

    .aa-tech-specs-container--mobile .aa-accordion-item__body {
        padding-bottom: 20px;
    }

    .aa-tech-specs-container--mobile .aa-tech-specs-item__number {
		padding-top: 20px;
	}

	.aa-tech-specs-item .aa-tech-specs-item__title {
		margin-right: 15px;
	}

	.aa-tech-specs-item .aa-tech-specs-item__number {
		margin-left: auto;
		padding-left: 20px;
		padding-right: 20px;
		max-width: 35%;
	}
}
/*-----------------------------------*\
    Scroll animations
\*-----------------------------------*/

.js-scroll {
	opacity: 0;
}

.js-scroll.scrolled {
	opacity: 1;
}

.scrolled.fade-in {
	animation: fade-in 1s ease-in-out both;
}

.scrolled.fade-in-bottom {
	animation: fade-in-bottom 1s ease-in-out both;
}

.scrolled.fade-in-bottom-delayed {
	animation: fade-in-bottom 1.4s ease-in-out both;
}

.scrolled.fade-in-top {
	animation: fade-in-top 1s ease-in-out both;
}

.scrolled.slide-left {
	animation: slide-in-left 1s ease-in-out both;
}

.scrolled.slide-right {
	animation: slide-in-right 1s ease-in-out both;
}

@keyframes slide-in-left {
	0% {
		transform: translateX(-100px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slide-in-right {
	0% {
		transform: translateX(100px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes fade-in-bottom {
	0% {
		transform: translateY(50px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes fade-in-top {
	0% {
		transform: translateY(-50px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@media (prefers-reduced-motion) {
	.js-scroll {
		opacity: 1;
	}
	.scrolled {
		animation: none !important;
	}
}
/*-----------------------------------*\
    Knowledge Cards
\*-----------------------------------*/

.aa-knowledge-cards {
	position: relative;

    .aa-inner {
		position: relative;
		z-index: 12;
        max-width: $full-site-width;
        margin-left: auto;
		margin-right: auto;
		flex-direction: column;
	}
}

.aa-knowledge-cards__header {
	max-width: calc(#{$full-site-width} / 12 * 5);
	margin-right: auto;
	margin-bottom: 30px;

    @include bp(lg) {
        margin-bottom: 60px;
	}
}

.aa-knowledge-cards__body {
    width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	column-gap: 45px;
	row-gap: 65px;

	@include bp(md) {
		grid-template-columns: repeat(3, 1fr);
		max-width: 100%;
		row-gap: 45px;
	}
}

.aa-knowledge-card {
	position: relative;
	z-index: 6;
	background-color: $white;
	box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
	clip-path: polygon(0px 0px, 100% 0px, 100% 88%, 0px 100%);

    display: flex;
    flex-direction: column;
    width: 100%;
	padding: 30px;
	text-decoration: none;
	color: $dark;

    transition: all $speed $animation;

	@include bp(lg) {
		padding: 60px;
	}

    &:hover {
        background-color: $white;
        box-shadow: 0 4px 32px 0 rgba($black, .2);
		color: $dark;
		text-decoration: none;

		.aa-knowledge-card__header img {
			transform: scale(1.2);
		}
    }
}

.aa-knowledge-card.aa-card-nolink {
	transition: none;

	&:hover {
		background-color: $white;
		box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
        text-decoration: none;
    }
}

.skewed-shadow {
	position: relative;
	z-index: 6;
	filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.1));

	&:after {
		content: "";
		width: 49px;
		height: 49px;
		position: absolute;
		z-index: 2;
		right: -14px;
		top: -14px;
		background-color: #575757;
	}

	.aa-knowledge-card {
		background-image: url('https://f.hubspotusercontent20.net/hubfs/9441822/avidly-theme/assets/images/GRAPHIC-03%20(9).png');
		background-position: top right;
		background-repeat: no-repeat;
		background-size: 230px;
	}

	&:nth-of-type(2) {
		&:after {
			background-color: #2C574B;
		}

		.aa-knowledge-card {
			background-image: url('https://f.hubspotusercontent20.net/hubfs/9441822/avidly-theme/assets/images/GRAPHIC-03%20(10).png');
			background-position: 108% 100%;
			background-repeat: no-repeat;
			background-size: 160px;
		}
	}

	&:nth-of-type(3) {
		&:after {
			background-color: #80A1B2;
		}

		.aa-knowledge-card {
			background-image: url('https://f.hubspotusercontent20.net/hubfs/9441822/avidly-theme/assets/images/GRAPHIC-03%20(8).png');
			background-position: top left;
			background-repeat: no-repeat;
			background-size: 230px;
		}
	}
}

.aa-knowledge-card__header {
	margin-bottom: 20px;

    img {
		transition: all $animation 0.3s;
		margin-left: auto;
		margin-right: auto;
		width: 85px;
		height: auto;
    }
}

.aa-knowledge-card__body {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
	padding-top: 20px;
	padding-bottom: 40px;
	text-align: center;

    transition: all $speed $animation;
}

// Modifiers for layout

.aa-knowledge-cards--2 {
	.aa-cards__body {
		@include bp(sm) {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}
/*-----------------------------------*\
    Mixins
\*-----------------------------------*/

/*
 * Declaration of responsive breakpoints available for use, kept up-to-date with latest Bootstrap version.
 * https://getbootstrap.com/docs/4.3/layout/overview/#responsive-breakpoints
 * hg (huge) and en (enourmous) are our own variations, to support specific styling for even bigger screens.
 */
$breakpoints: (
    'sm'  : 576px,
    'md'  : 768px,
    'lg'  : 992px,
    'xl'  : 1200px,
    'hg'  : 1440px,
    'en'  : 1680px
);

@mixin bp($breakpoint) {
    $value: map-get($breakpoints, $breakpoint);

    @if $value != null {
        @media (min-width: $value) {
            @content;
        }
    }
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
    }
}
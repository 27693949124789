/*-----------------------------------*\
  Videos
\*-----------------------------------*/

.aa-video-container {
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;
    background-size: cover;
    background-repeat: no-repeat;

    video {
        position: absolute;
        width: 100%;
        height: 100%;
        height: auto;
        background-size: cover;
        transform: none;
        margin-left: 0;
        object-fit: cover;
    }
}

.aa-video-container--youtube {
    padding-bottom: 0;
}

.aa-video-thumbnail {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	z-index: 1;
	opacity: 1;
	transition: opacity $speed $animation;

	cursor: pointer;

	&:before {
        content: '';
        z-index: 7;
        position: absolute;
        width: 100%;
        height: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(0deg, rgba($black, 0.2), rgba($black, 0.2));
	}

	&.is-hidden {
        opacity: 0;
        visibility: hidden;
    }

    + video {
        visibility: hidden;
    }

    &.is-hidden + video {
        visibility: visible;
    }

	.aa-play {
		pointer-events: none;
	}
}

// Video play button
.aa-video-button {
    // cursor: pointer;
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    z-index: 12;

    p {
        margin-left: 10px;
        font-size: 13px;
        line-height: 16px;
        text-transform: uppercase;
        font-weight: $weight-400;
        color: $white;

        @include bp(sm) {
            font-size: 16px;
            line-height: 19px;
        }
    }
}
.aa-play {
    // Browser reset of <button>
    appearance: none;
    border: 0;
    height: 45px;
    width: 45px;
    background-color: $secondary;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 12;

    transition: all $speed ease;

    @include bp(sm) {
        height: 70px;
        width: 70px;
    }

    &:before {
        position: relative;
        content: '';
        height: 16px;
        width: 12px;
        margin-left: 3px;
        background-image: url("data:image/svg+xml,%3Csvg width='17' height='21' viewBox='0 0 17 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.4825 10.5461L16.4815 10.5468L0.517901 20.4983C0.514486 20.4961 0.510421 20.4922 0.507137 20.4864C0.504175 20.4812 0.5 20.4714 0.5 20.4536V0.54356C0.5 0.525719 0.504197 0.516019 0.507001 0.51111C0.509125 0.507391 0.511501 0.504647 0.513626 0.502725L16.4815 10.457L16.4825 10.4576C16.4871 10.4604 16.49 10.4634 16.4929 10.469C16.4965 10.4759 16.5 10.4874 16.5 10.5019C16.5 10.5163 16.4965 10.5278 16.4929 10.5347C16.49 10.5403 16.4871 10.5433 16.4825 10.5461Z' stroke='white'/%3E%3C/svg%3E%0A");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 12;

        @include bp(sm) {
            height: 22px;
            width: 18px;
        }
    }

    &:hover {
        cursor: pointer;
    }
}

// YouTube videos
.aa-youtube {
    background-color: $black;
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $black;
        opacity: .3;
    }

    .aa-play {
        position: absolute;
        bottom: 20px;
        left: 20px;
    }
}
.aa-youtube img {
    width: 100%;
    height: auto;
    top: -16.84%;
    left: 0;
}


.aa-youtube img,
.aa-youtube iframe {
    position: absolute;

}

.aa-youtube iframe {
    z-index: 2;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

/*-----------------------------------*\
  Image
\*-----------------------------------*/

.aa-image {
    .aa-content-width--narrow {
        @include bp(lg) {
            max-width: calc(#{$full-site-width} / 12 * 8);
        }
    }

    .aa-content-width--normal {
        @include bp(lg) {
            max-width: calc(#{$full-site-width} / 12 * 10);
        }
    }

    .aa-content-width--wide {
        @include bp(lg) {
            max-width: calc(#{$full-site-width} / 12 * 12);
        }
    }

    .aa-content-width--full {
        @include bp(lg) {
            max-width: 100%;
        }
    }

    img {
        width: 100%;
    }
}

.aa-image .aa-content {
    margin-left: auto;
    margin-right: auto;
}

.aa-image--full-page {
    padding-left: $padding;
    padding-right: $padding;

    @include bp(md) {
        padding-left: 0;
        padding-right: 0;
    }
}
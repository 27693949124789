/*-----------------------------------*\
Social Links
\*-----------------------------------*/

.aa-social-links {
    display: flex;
    align-items: center;
    justify-content: center;
}

.aa-social-links__icon {
    background-color: $primary;
    border-radius: 50%;
    display: inline-flex;
    height: 40px;
    width: 40px;
    margin: 0 5px;
    position: relative;

    transition: all $speed $animation;
}

.aa-social-links__icon:hover,
.aa-social-links__icon:focus {
    // background-color: darken($primary, 10%);
}


.aa-social-links__icon svg {
    fill: $white;
    height: 15px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
}

.aa-social-links__icon:hover svg,
.aa-social-links__icon:focus svg,
.aa-social-links__icon:active svg {
    fill: $white;
}

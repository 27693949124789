/*-----------------------------------*\
    Footer
\*-----------------------------------*/

.aa-site-footer {
    @extend %burj-top-margin;

    width: 100%;

    padding-left: $padding;
    padding-right: $padding;
}

.aa-site-footer__inner {
    max-width: $full-site-width;
    margin-left: auto;
    margin-right: auto;
}

.aa-site-footer-image {
    img {
        width: 100%;
        height: auto;
    }
}

.aa-site-footer__primary {
    @extend %empire-bottom;
    @extend %empire-top;

    width: 100%;
    border-bottom: 1px solid #80A1B2;

    @include bp(lg) {
        display: flex;
        flex-direction: row;
        max-width: $full-site-width;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
    }
    @include bp(hg) {
        padding-left: 0;
        padding-right: 0;
    }
}

.aa-site-footer__primary__header {
    width: 100%;
    padding-right: 30px;

    @include bp(lg) {
        max-width: calc(#{$full-site-width} / 12 * 4);
    }

    .aa-title {
        margin-bottom: 35px;
    }

    .footer-subtitle {
        @extend %eiffel-bottom-margin;

        color: $white;
    }
}

.aa-site-footer__form {
    margin-bottom: 20px;

    .hs-submit {
        text-align: left;
    }

    .hs-input::placeholder {
        color: $black;
    }

    .hs-input{
        border-radius: 100px;
        background-color: $light;
        border-color: $light;
        color: $black;
        padding-left: 25px;

        @include bp(sm) {
            padding-right: 220px;
        }
    }

    .hs-form .hs-button {
        background-color: $secondary;
        color: $white;
    }

    ul.hs-error-msgs label {
        color: $white;
    }

    .legal-consent-container .hs-dependent-field .input .hs-form-booleancheckbox {
        margin: 0;
    }

    @include bp(sm) {
        margin-bottom: 0;

        .hs-form {
            position: relative;
        }

        .hs-submit {
            text-align: center;
            position: absolute;
            top: 0;
            right: -1px;
            margin-top: 0;
        }
    }
}

.aa-site-footer__primary__body {
    width: 100%;

    @include bp(lg) {
        display: flex;
        max-width: calc(#{$full-site-width} / 12 * 5);
    }
}

.aa-site-footer__primary__column {
    width: 50%;
}

.aa-site-footer__secondary {
    padding-top: 30px;
    padding-bottom: 30px;

    @include bp(lg) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.aa-site-footer__secondary__body {

    ul {
        margin: 0;
        padding: 0;
        margin-top: 30px;

        @include bp(sm) {
            display: flex;
        }

        @include bp(lg) {
            margin-top: 0;
        }
    }

    li {
        list-style: none;
        margin-bottom: 20px;

        &:last-of-type {
            padding-right: 0;
            margin-bottom: 0;
        }

        @include bp(sm) {
            padding-right: 20px;
            padding-left: 20px;
            margin-bottom: 0;

            &:first-of-type {
                padding-left: 0;
            }
        }
    }

    span {
        display: none;

        @include bp(sm) {
            display: inline-block;
            color: $secondary;
        }
    }

    a {
        color: $white;
        text-decoration: none;

        &:hover {
            color: rgba($white, 0.8);
        }
    }
}
/*-----------------------------------*\
    AA - Cases
\*-----------------------------------*/
$container-width: $full-site-width;
$columns: 12;
$gap: 62px;
$column-width: ($container-width / $columns) - $gap;

// 1. Fallback styles for browsers which
//    don't support `display: grid`.
// 2. Reset fallback style.


.aa-columns-cases {
	padding-left: 0;
	padding-right: 0;

 	.aa-inner {
		flex-direction: column;
		max-width: 100%;
	}
}

.aa-columns-cases__header {
	@extend %eiffel-bottom;

	max-width: $full-site-width;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-left: $padding;
	padding-right: $padding;

	@include bp(en) {
		padding-left: 0;
		padding-right: 0;
	}

	.aa-columns-cases__title {
		max-width: calc(#{$full-site-width} / 12 * 5);
	}
}

.aa-columns-cases__body {
	display: grid;
	row-gap: 45px;

	@include bp(lg) {
		row-gap: 90px;
	}
}

.aa-columns-case {
	max-width: $container-width; // 1
	margin-right: auto; // 1
	margin-left: auto; // 1

	@include bp(lg) {
		display: flex; // 1
	}

	@supports (display: grid) {
		@include bp(lg) {
			display: grid;
			grid-gap: $gap;
			grid-template-columns: minmax(1em, 1fr) repeat($columns, minmax(0, $column-width)) minmax(1em, 1fr);
			max-width: 100%;
			width: 100%;
			grid-template-areas: 'img text';
		}
	}

	&:hover {
		@include bp(xl) {
			.aa-button-container {
				max-height: 46px;
			}
		}
	}
}

.aa-columns-case--image {
	position: relative;

	grid-column-start: 1;
	grid-column-end: 15;
	display: flex;
	grid-area: img;

	@include bp(lg){
		grid-column-start: 1;
		grid-column-end: 10;
	}

	.aa-column__image {
		width: 100%;
		overflow: hidden;

		img {
			transition: all 0.5s $animation;
		}
	}

	.aa-columns-case--icons {
		position: absolute;
		right: auto;
		left: 10px;
		bottom: 10px;
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		@include bp(lg) {
			max-width: calc(#{$full-site-width} / 12 * 2);
			left: 20px;
			right: auto;
			bottom: 20px;
		}

		img {
			width: 35px;
			height: 35px;
			margin-right: 5px;
			margin-bottom: 5px;

			@include bp(lg) {
				width: 75px;
				height: 75px;
			}

			&:last-of-type {
				margin-right: 0;
			}
		}
	}
}

.aa-columns-case--text {
	@extend %eiffel-top;

	grid-column-start: 2;
	grid-column-end: 14;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 2em; // 1
	margin-right: 1em; // 1
	margin-left: 1em; // 1
	grid-area: text;
	hyphens: manual;
	padding-left: $padding;

	@include bp(lg) {
		padding-bottom: 64px;
		grid-column-start: 10;
		grid-column-end: 14;
		margin-top: 0; // 1
	}

	@include bp(en) {
		padding-left: 0;

		.aa-button-container {
			transition: all 0.3s $animation;
			max-height: 0;
			overflow: hidden;
		}
	}

	@supports (display: grid) {
		margin-top: 0; // 2
		margin-left: 0;

		@include bp(lg) {
			margin-right: -32px;
		}
	}

	.aa-button-container {
		flex-wrap: initial;

		@include bp(sm) {
			flex-direction: row;
		}

		@include bp(lg) {
			flex-direction: column;
		}

		@include bp(xl) {
			flex-direction: row;
		}

		.aa-button + .aa-button {
			margin-top: 20px;

			@include bp(sm) {
				margin-top: 0;
				margin-left: 20px;
			}

			@include bp(lg) {
				margin-top: 20px;
				margin-left: 0px;
			}

			@include bp(xl) {
				margin-top: 0;
				margin-left: 20px;
			}
		}
	}
}

.aa-columns-case:nth-of-type(even) {
	@supports (display: grid) {
		grid-template-areas: 'text img';
	}

	.aa-columns-case--image {
		@include bp(lg) {
			grid-column-start: 6;
			grid-column-end: 15;
		}
	}

	.aa-columns-case--text {
		padding-right: $padding;

		@include bp(lg) {
			grid-column-start: 2;
			grid-column-end: 6;
			margin-left: -46px;
			margin-right: 0;
		}

		@include bp(xl) {
			padding-right: 0;
		}
	}

	.aa-columns-case--icons {

		@include bp(lg) {
			max-width: calc(#{$full-site-width} / 12 * 2);
			left: auto;
			right: 20px;
			bottom: 20px;
		}

		img {
			@include bp(lg) {
				margin-left: 5px;
				margin-right: 0;

				&:last-of-type {
					margin-right: 5px;
					margin-left: 0;
				}
			}
		}
	}
}

// Modifiers

.aa-columns-cases--reversed {

	.aa-columns-case {
		@supports (display: grid) {
			@include bp(lg) {
				grid-template-areas: 'text img';
			}
		}
	}

	.aa-columns-case--image {
		@include bp(lg){
			grid-column-start: 6;
			grid-column-end: 15;
		}

		.aa-columns-case--icons {
			right: auto;
			left: 10px;
			bottom: 10px;

			@include bp(lg) {
				left: 20px;
				right: auto;
			}

			img {
				margin-right: 5px;
				margin-bottom: 5px;

				&:last-of-type {
					margin-right: 0;
				}
			}
		}
	}

	.aa-columns-case--text {
		grid-column-start: 2;
		grid-column-end: 14;
		padding-right: $padding;

		@include bp(lg) {
			padding-bottom: 64px;
			grid-column-start: 2;
			grid-column-end: 6;
		}

		@include bp(xl) {
			padding-right: 0;
		}

		@supports (display: grid) {
			margin-top: 0; // 2
			margin-left: 0;

			@include bp(lg) {
				margin-left: -32px;
			}
		}
	}


	.aa-columns-case:nth-of-type(even) {
		@supports (display: grid) {
			grid-template-areas: 'img text';
		}

		.aa-columns-case--image {
			@include bp(lg){
				grid-column-start: 1;
				grid-column-end: 10;
			}
		}

		.aa-columns-case--text {
			padding-left: $padding;

			@include bp(lg) {
				grid-column-start: 10;
				grid-column-end: 14;
				margin-left: -32px;
				margin-right: 0;
			}
			@include bp(xl) {
				padding-left: 0;
			}
		}

		.aa-columns-case--icons {
			@include bp(lg) {
				right: auto;
				left: 20px;
			}

			img {
				@include bp(lg) {
					margin-right: 5px;
					margin-left: 0;

					&:last-of-type {
						margin-left: 5px;
						margin-right: 0;
					}
				}
			}
		}
	}
}

.aa-columns-cases--no-spacing {
	.aa-columns-cases__body {
		row-gap: 0;

		@include bp(lg) {
			row-gap: 0;
		}
	}
}

.aa-columns-cases--no-hover {
	.aa-columns-case {
		.aa-button-container {
			max-height: 100%;
		}

		&:hover {
			@include bp(lg) {
				.aa-columns-case--image .aa-column__image {
					img {
						transform: none;
					}
				}
			}
		}
	}
}

//popup styling overwrites

.aa-columns-cases {
	.aa-modal__inner {
		position: relative;
		max-width: 720px;
	}
	.aa-modal__body {
		position: static;

		.popup_text {
			margin-bottom: 30px;
		}
	}
}
/*-----------------------------------*\
    Typography
\*-----------------------------------*/

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: 16px;
    line-height: 22px;
    font-family: $body-font;
    font-weight: $weight-400;

    color: $body-color;

    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: manual;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    clear: both;

    font-family: $heading-font;
    color: $heading-color;

    // Don't break headlines on mobile
    // br {
    //     display: block;
    // }

    // @include bp(lg) {
    //     br {
    //         display: block;
    //     }
    // }

    &:last-child {
        margin-bottom: 0;
    }
}

h1, .h1 {
    margin-bottom: 20px;

    font-size: 40px;
    line-height: 46px;
    font-family: $h1-font-family;
    font-weight: $h1-font-weight;
    font-style: $h1-font-style;
    text-decoration: $h1-text-decoration;

    @include bp(lg) {
        font-size: 64px;
        line-height: 70px;
    }
}

h1.aa-custom-title {
    font-size: 40px;
    line-height: 46px;
    font-weight: $weight-900;
    color: $primary;

    @include bp(md) {
        background: $primary;
        background: linear-gradient(260.12deg, #EC6608 10.19%, #4E8B79 83.19%);
        background-size: 100% 100%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    @include bp(lg) {
        font-size: 87px;
        line-height: 95px;
    }
}

h2, .h2 {
    margin-bottom: 20px;

    font-size: 28px;
    line-height: 34px;
    font-family: $h2-font-family;
    font-weight: $h2-font-weight;
    font-style: $h2-font-style;
    text-decoration: $h2-text-decoration;

    @include bp(lg) {
        margin-bottom: 30px;

        font-size: 40px;
        line-height: 45px;
    }
}

h3, .h3 {
    margin-bottom: 20px;

    font-size: 22px;
    line-height: 30px;
    font-family: $h3-font-family;
    font-weight: $h3-font-weight;
    font-style: $h3-font-style;
    text-decoration: $h3-text-decoration;

    @include bp(lg) {
        font-size: 32px;
        line-height: 38px;
    }
}

h4, .h4 {
    margin-bottom: 20px;

    font-size: 20px;
    line-height: 30px;
    font-family: $h4-font-family;
    font-weight: $h4-font-weight;
    font-style: $h4-font-style;
    text-decoration: $h4-text-decoration;

    @include bp(lg) {
        font-size: 26px;
        line-height: 31px;
    }

}

h5, .h5 {
    margin-bottom: 10px;

    font-size: 16px;
    line-height: 22px;
    font-family: $h5-font-family;
    font-weight: $h5-font-weight;
    font-style: $h5-font-style;
    text-decoration: $h5-text-decoration;

    @include bp(hg) {
        font-size: 20px;
        line-height: 30px;
    }
}

h6, .h6 {

    font-size: 16px;
    line-height: 19px;
    font-family: $h6-font-family;
    font-weight: $h6-font-weight;
    font-style: $h6-font-style;
    text-decoration: $h6-text-decoration;
}

p {
    margin-top: 0;
    margin-bottom: 0;

    &:not(:last-child) {
        margin-bottom: 20px;

        @include bp(md) {
            margin-bottom: 30px;
        }
    }
}

.aa-text-size--small {
    font-size: 14px;
    line-height: 20px;

    @include bp(lg) {
        font-size: 16px;
        line-height: 24px;
    }
}

.aa-text-size--large {
    font-size: 18px;
    line-height: 26px;

    @include bp(lg) {
        font-size: 20px;
        line-height: 30px
    }
}

ul, ol {
    padding-left: 30px;
    margin-top: 20px;
    margin-bottom: 20px;

    @include bp(md) {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-top: 0;
    }
}

img + p,
img + strong,
img + h1,
img + h2,
img + h3,
img + h4,
img + h5,
img + h6,
p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
    margin-top: 30px;
}

blockquote {
    @extend %eiffel-top-margin;
    @extend %eiffel-bottom-margin;
    @extend .h3;

    margin-left: 0; // Browser reset
    margin-right: 0; // Browser reset

    font-size: 22px;
    line-height: 30px;
    font-weight: $weight-700;

    @include bp(lg) {
        font-size: 25px;
        line-height: 30px;
    }
}

strong {
    font-weight: $weight-900;
}

i {
    font-style: italic;
}

figcaption {
    font-family: var(--body_font);
    font-size: 15px;
    line-height: 21px;
    font-style: italic;
}

.aa-subheading,
.aa-label {
    display: block;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 20px;
    font-weight: $weight-400;

    @include bp(lg) {
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 22px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.aa-title-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: $secondary;
    margin-left: 4px;
}
/*-----------------------------------*\
    Info numbers
\*-----------------------------------*/

.aa-info-numbers {
	.aa-inner {
		flex-direction: column;
		position: relative;
		max-width: $full-site-width;
	}
}

.aa-info-numbers__header {
	max-width: calc(#{$full-site-width} / 12 * 5);
	margin-bottom: 30px;
}

.aa-info-numbers__body {
	position: relative;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	z-index: 12;

	img {
		width: 100%;
		height: auto;

		@include bp(lg) {
			max-width: 840px;
		}
	}
}

.aa-info-numbers__content {

	@include bp(lg) {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		position: absolute;
		z-index: 6;
		bottom: -90px;
		right: 0;
	}
}

.aa-info-numbers__title-box {
	display: flex;
	justify-content: center;
	flex-direction: column;

	background-color: $secondary;
	color: $white;
	padding: 40px 20px;

	font-size: 24px;
	line-height: 26px;

	@include bp(lg) {
		max-width: 240px;
	}
}

.aa-info-numbers__counter {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	background-color: $white;
	box-shadow: $box-shadow;

	@include bp(lg) {
		max-width: calc(#{$full-site-width} / 12 * 7);
		padding: 60px;
	}
}

.aa-info-number__item {
	text-align: center;
	padding: 25px;

	@include bp(lg) {
		padding: 40px;
	}

	&:first-of-type {
		border-right: 1px solid #E3E9EC;
		border-bottom: 1px solid #E3E9EC;
	}
	&:nth-of-type(2) {
		border-bottom: 1px solid #E3E9EC;
	}
	&:nth-of-type(3) {
		border-right: 1px solid #E3E9EC;
	}
}

.aa-info-number__content {
	display: flex;
	justify-content: center;
	margin-bottom: 15px;

	h2 {
		font-weight: $weight-900;
		margin-bottom: 0;
	}
}

.aa-info-number-extension {
	color: $secondary;
}
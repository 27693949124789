/*-----------------------------------*\
  AA - Hero Product
\*-----------------------------------*/

.aa-hero--product {
	flex-direction: column;
	margin-bottom: 100px;

	@include bp(md) {
		margin-bottom: 200px;
	}

	@include bp(md) {
		margin-bottom: 200px;
		padding-top: 120px;
	}

	.aa-inner {
		max-width: calc(#{$full-site-width} / 12 * 10);
		padding-bottom: 20px;
	}

	.aa-hero__body {
		li {
			position: relative;
			list-style: none;

			padding-left: 25px;

			&:before {
				content: '';
				position: absolute;
				top: 7px;
				left: 0px;
				display: inline-block;
				width: 10px;
				height: 10px;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H10V10H0V0Z' fill='%23EC6608'/%3E%3C/svg%3E%0A");
			}
		}
	}
}

.aa-hero--product__image {
	position: relative;

	margin-left: auto;
	margin-right: auto;
	margin-bottom: -100px;
	max-width: 720px;
	width: 100%;

	@include bp(md) {
		margin-bottom: -200px;
	}

	img {
		width: 100%;
	}

	.dot {
		position: absolute;
		border-radius: 50%;
		width: 47px;
		height: 47px;
		cursor: pointer;
		background-image: url('https://www.greenhydrogensystems.com/hubfs/Avidly%20Theme/assets/images/Ellipse%208.png');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);

		transition: all ease-in-out 0.2s;


		.info-container {
			display: none;
			position: relative;
			width: 47px;
			height: 47px;

			&:after {
				content: "";
				width: 50px;
				height: 1px;
				border: 1px dashed #80a1b2;
				position: absolute;
				transform: rotate(128deg);
				right: -27px;
				top: -16px;
			}

			.info-box {
				position: absolute;
				z-index: 12;
				right: 0;
				top: -15px;
				width: 280px;
				padding: 20px;
				transform: translateY(-100%) translateX(100%);

				background: #FFFFFF;
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
				border-radius: 6px;
			}
		}
	}

	.dot.is-active {
		background-image: url('https://www.greenhydrogensystems.com/hubfs/Avidly%20Theme/assets/images/Ellipse%205.png');
		transition: all ease-in-out 0.2s;

		.info-container {
			display: block;
		}
	}

	.info-container--mobile {
		position: relative;
		z-index: 12;
		width: 90%;
		max-width: 450px;
		padding: 20px;
		margin-top: -50px;

		background: #FFFFFF;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
		border-radius: 6px;
	}
}
/*-----------------------------------*\
    Utilities
\*-----------------------------------*/

.aa-bg-image {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
}

.aa-bg-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.aa-object-cover,
.aa-object-contain {
    img {
        height: 100%;
        width: 100%;
    }
}

.aa-object-cover img {
    object-fit: cover;
}

.aa-object-contain img {
    object-fit: contain;
}

/*
 * Background colors
 */
.aa-bg-primary {
    color: $primary-bg-text;

    .aa-label,
    .aa-subheading {
        color: $primary-bg-accent;
    }

    h1,h2,h3,h4,h5,h6 {
        color: $primary-bg-accent;
    }

    a {
        color: $primary-bg-link;
        text-decoration: underline;

        &:hover, &:active, &:focus {
            color: $primary-bg-link-hover;
        }
    }

    .hs-input {
        border-color: $primary-bg-accent;
    }
}

.aa-bg-secondary {
    color: $secondary-bg-text;

    .aa-label,
    .aa-subheading {
        color: $secondary-bg-accent;
    }

    h1,h2,h3,h4,h5,h6 {
        color: $secondary-bg-accent;
    }

    a {
        color: $secondary-bg-link;
        text-decoration: underline;

        &:hover, &:active, &:focus {
            color: $secondary-bg-link-hover;
        }
    }

    .hs-input {
        border-color: $secondary-bg-accent;
    }
}

.aa-bg-complementary {
    color: $complementary-bg-text;

    .aa-label,
    .aa-subheading {
        color: $complementary-bg-accent;
    }

    h1,h2,h3,h4,h5,h6 {
        color: $complementary-bg-accent;
    }

    a {
        color: $complementary-bg-link;
        text-decoration: underline;

        &:hover, &:active, &:focus {
            color: $complementary-bg-link-hover;
        }
    }

    .hs-input {
        border-color: $complementary-bg-accent;
    }
}

.aa-bg-light {
    color: $light-bg-text;

    .aa-label,
    .aa-subheading {
        color: $light-bg-accent;
    }

    h1,h2,h3,h4,h5,h6 {
        color: $black;
    }

    a {
        color: $light-bg-link;
        text-decoration: underline;

        &:hover, &:active, &:focus {
            color: $light-bg-link-hover;
        }
    }

    .hs-input {
        border-color: $light-bg-accent;
    }
}

.aa-bg-dark {
    color: $dark-bg-text;

    .aa-label,
    .aa-subheading {
        color: $dark-bg-accent;
    }

    h1,h2,h3,h4,h5,h6 {
        color: $dark-bg-accent;
    }

    a {
        color: $dark-bg-link;
        text-decoration: underline;

        &:hover, &:active, &:focus {
            color: $dark-bg-link-hover;
        }
    }

    .hs-input {
        border-color: $dark-bg-accent;
    }
}

.scrolled-first {
    background-color: $white;
}

.body-bg-white {
    background-color: $white;
}

.body-bg-light {
    background-color: $light;

    .aa-article-card {
        background-color: $light;
    }

    .aa-article-card__body {
        background-color: $light;
    }
}

.body-bg-primary {
    background-color: $primary;

    .aa-inner,
    .aa-site-footer__inner {
        color: $white !important;
    }

    .aa-navigation--mobile ul li a {
        color: $black !important;
    }

    h1.aa-title, h1,
    h2.aa-title, h2,
    h3.aa-title, h3,
    h4.aa-title, h4,
    a,
    .aa-article-cards__title h2 {
        color: $white !important;
    }

    .aa-form-button--1 {
        .hs-button {
            border: 1px solid $white;
            color: $white;

            &:hover {
                border: 1px solid $secondary;
                background-color: $secondary;
            }
        }
    }

    .aa-quote .aa-inner {
        color: $dark !important;
    }

    .aa-article-card__title {
        color: $primary !important;
    }

    .aa-blog-post-content {
        color: $white !important;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        blockquote,
        span,
        a {
            color: $white !important;
        }
    }

    .aa-article-cards__title {
        h1,h2,h3,h4,h5,h6 {
            color: $black !important;
        }
    }

    .aa-info-card__body {
        h1,h2,h3,h4,h5,h6 {
            color: $black !important;
        }
    }

    .aa-article-cards__button .aa-button {
        color: $secondary !important;
    }

    .aa-article-cards__title {
        color: $black !important;
    }

    .aa-bg-light {
        .aa-inner {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            blockquote,
            span,
            a {
                color: $dark !important;
            }

            .hs-input {
                color: $dark !important;
            }
        }
    }

    .aa-site-header a {
        color: $dark !important;
    }

    .aa-pagination li a {
        color: $dark !important;
    }

    .aa-pagination li a.aa-pagination--active {
        color: $white !important;
    }

    // popup overrides cta

    .leadin-message-wrapper {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        blockquote,
        span,
        a {
            color: $dark !important;
        }

        .hs-input {
            color: $dark !important;
        }
    }

    /* Custom checkbox */
    .aa-content-color--dark ul.inputs-list.multi-container li.hs-form-checkbox,
    .aa-content-color--dark ul.inputs-list li.hs-form-booleancheckbox,
    ul.inputs-list.multi-container li.hs-form-checkbox,
    ul.inputs-list li.hs-form-booleancheckbox {

        // Add new input
        label span:not(.hs-form-required)::before {
            border: 1px solid $white;
            top: 2px;
        }

        // Checked item
        label span:not(.hs-form-required)::after {
            border: 1px solid $white;
            background: $white;
            top: 5px;
        }
    }

    /* Custom radio button */
    .aa-bg-image .aa-panels__form ul.inputs-list.multi-container li.hs-form-radio,
    ul.inputs-list.multi-container li.hs-form-radio {
        // Show checked style
        label input:checked + span::after {
            background-color: $white;
            border: 1px solid $white;
        }
    }
}

/*
 * Generate utility classes and styling for all background colors defined in $colors
 */
@each $key, $color in $main-colors {
    .aa-bg-#{$key} {
        background-color: $color;
    }

    .aa-bg-overlay--#{$key} {
        @extend .aa-bg-#{$key};

        &:before {
            background-color: $color;
            opacity: 0.8;
        }

        &:after {
            background: linear-gradient(106.45deg, $color 13.16%, rgba(78, 139, 121, 0) 90.33%);
        }
    }

    .aa-content-color--#{$key} {
        @if $key == 'light' {
            color: $text-light;
        } @else if $key == 'dark' {
            color: $text-dark;
        } @else {
            color: $color;
        }
    }
}

.aa-bg-overlay {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        z-index: 5;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        z-index: 6;
    }

    > .aa-inner {
        position: relative;
        z-index: 10;
    }

    h1,h2,h3,h4,h5,h6 {
        color: $white;
    }
}

.aa-content-color--light {
    h1.aa-custom-title {
        background: $white;
        background: linear-gradient('');
        background-size: 100% 100%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.aa-content-color--dark {
    h1.aa-custom-title {
        background: $dark;
        background: linear-gradient('');
        background-size: 100% 100%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.aa-bg-video {
    position: relative;

    > .aa-inner {
        position: relative;
        z-index: 10;
    }
}

.aa-bg-video video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

// IE11 responsive background video: https://jsfiddle.net/StartBootstrap/enajc82d/
.ie .aa-bg-video {
    overflow: hidden;
}

.ie .aa-bg-video video {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    min-width: 100%;
    min-height: 100%;
    max-height: none;
    width: auto;
    height: auto;
    padding: 0;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%);
}

 /*
 * The container of a module
 */
.aa-module {
    display: flex;

    padding-left: $padding;
    padding-right: $padding;
}

/*
 * Offset top
*/

.aa-module--offset {
    margin-top: -400px;
}

/*
 * Sets the width of the module
 */
.aa-module-width--page,
.aa-module-width--full {
    width: 100%;
}

.aa-module-width--page {
    @include bp(lg) {
        max-width: $full-site-width;
        margin-left: auto;
        margin-right: auto;
    }
}

/*
 * Sets the height of the module
 */
.aa-module-height--small,
.aa-module-height--normal,
.aa-module-height--full {
    display: flex;
    align-items: center;
}
.aa-module-height--small {
    min-height: 400px;

    @media (max-height: 400px) {
        min-height: 100vh;
    }
}
.aa-module-height--normal {
    min-height: 800px;

    @media (max-height: 800px) {
        min-height: 100vh;
    }
}
.aa-module-height--full {
    min-height: 100vh;
}

/*
 * Sets the spacing on the module
 */
.aa-module-spacing--small {
    @extend %eiffel-top;
    @extend %eiffel-bottom;
}
.aa-module-spacing--medium {
    @extend %empire-top;
    @extend %empire-bottom;
}
.aa-module-spacing--large {
    @extend %burj-top;
    @extend %burj-bottom;
}

.aa-module-spacing-bottom--small {
    @extend %eiffel-bottom;
}
.aa-module-spacing-bottom--medium {
    @extend %empire-bottom;
}
.aa-module-spacing-bottom--large {
    @extend %burj-bottom;
}

.aa-module-spacing-top--small {
    @extend %eiffel-top;
}
.aa-module-spacing-top--medium {
    @extend %empire-top;
}
.aa-module-spacing-top--large {
    @extend %burj-top;
}

/*
 * The container of a modules content
 */
.aa-content {
    width: 100%;
}

/*
 * Controls the position of the content within the module
 */
.aa-content-position--left {
    justify-content: flex-start;
}
.aa-content-position--center {
    justify-content: center;
}

/*
 * Controls the alignment of the content within the module
 */
.aa-content-alignment--left {
    text-align: left;
}
.aa-content-alignment--center {
    text-align: center;
}

.aa-inner {
    display: flex;
    width: 100%;

    @include bp(lg) {
        max-width: $full-site-width;
        margin-left: auto;
        margin-right: auto;
    }
}

/*
 * Sets the width of the content in a module
 */
.aa-content-width--narrow,
.aa-content-width--normal,
.aa-content-width--wide {
    width: 100%;
}
.aa-content-width--narrow {
    @include bp(md) {
        max-width: calc(#{$full-site-width} / 12 * 4);
    }
}
.aa-content-width--normal {
    @include bp(md) {
        max-width: calc(#{$full-site-width} / 12 * 6);
    }
}
.aa-content-width--wide {
    @include bp(lg) {
        max-width: calc(#{$full-site-width} / 12 * 8);
    }
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

/*
 * Bootstrap 4 hiding classes
 */
.hidden-xs-up {
    display: none !important;
}

@media (max-width: 543px) {
    .hidden-xs-down {
        display: none !important;
    }
}

@include bp(md) {
    .hidden-sm-up {
        display: none !important;
    }
}

@media (max-width: 767px) {
    .hidden-sm-down {
        display: none !important;
    }
}

@include bp(md) {
    .hidden-md-up {
        display: none !important;
    }
}

@media (max-width: 991px) {
    .hidden-md-down {
        display: none !important;
    }
}

@include bp(lg) {
    .hidden-lg-up {
        display: none !important;
    }
}

@media (max-width: 1199px) {
    .hidden-lg-down {
        display: none !important;
    }
}

@include bp(xl) {
    .hidden-xl-up {
        display: none !important;
    }
}

.hidden-xl-down {
    display: none !important;
}

.aa-logo-wall {

	.aa-inner {
        max-width: $max-site-width;
        margin-left: auto;
        margin-right: auto;
	}

	.aa-content {
		max-width: calc(#{$full-site-width} / 12 * 10);
		margin-left: auto;
        margin-right: auto;
	}
}

.aa-logo-wall__header {
	@extend %eiffel-bottom;

	text-align: center;

	.aa-title {
		color: $primary;
	}
}

.aa-logo-wall__body {
    width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	row-gap: 40px;

	@include bp(md) {
		grid-template-columns: repeat(4, 1fr);
        max-width: 100%;
		column-gap: 25px;
	}

	@include bp(xl) {
		grid-template-columns: repeat(5, 1fr);
	}
}

.aa-logo-item {
	img {
		max-width: 160px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		filter: grayscale(100%);
		transition: filter ease-in-out 0.3s;
	}

	&:hover {

		img {
			filter: grayscale(0);
		}
	}
}
/*-----------------------------------*\
    Case Cards
\*-----------------------------------*/

.aa-case-cards {
	position: relative;

	.bg-graphic-bottom-left,
	.bg-graphic-top-right {
		position: absolute;
		z-index: 2;
		height: auto;
	}

	.bg-graphic-bottom-left {
		bottom: 0;
		left: 0;
	}

	.bg-graphic-top-right {
		top: 150px;
		right: 0;
	}

    .aa-inner {
		position: relative;
		z-index: 6;
        max-width: $full-site-width;
        margin-left: auto;
		margin-right: auto;
		flex-direction: column;
    }
}

.aa-case-cards__header {
	max-width: calc(#{$full-site-width} / 12 * 7);
	margin-bottom: 30px;

	.aa-title {
		margin-bottom: 25px;
	}
}

.aa-case-cards__header--centered {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;

	@include bp(lg) {
        margin-bottom: 60px;
	}
}

.aa-case-cards__body {
    width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 20px;

	@include bp(lg) {
		grid-template-columns: repeat(4, 1fr);
		max-width: $full-site-width;
		row-gap: 60px;
	}
}

.aa-case-card {
	position: relative;
    display: flex;
	flex-direction: column;
	justify-content: flex-end;
	min-height: 530px;
    width: 100%;
	box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
	text-decoration: none;
	cursor: pointer;

    transition: all $speed $animation;

	&:hover {
		@include bp(lg) {
			.aa-case-card__read-more {
				overflow: visible;
				height: 31px;
			}
		}
	}
}

.aa-case-card__header {
    img {
		margin-left: auto;
		margin-right: auto;
    }
}

.aa-case-card__body {
	position: relative;
	z-index: 6;
    display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	margin-bottom: auto;
	padding: 20px;
	transition: all $speed $animation;
	hyphens: manual;
	color: $white;

	h1,h2,h3,h4,h5,h6 {
		color: $white;
	}

	p {
		margin-bottom: 10px;
	}
}

.aa-case-card__icon {
	position: absolute;
	z-index: 6;
	top: 14px;
	right: 14px;
	width: 84px;
	height: 84px;

	img {
		width: 100%;
		height: auto;
	}
}

.aa-case-card__read-more {
	font-weight: $weight-700;
	color: $secondary;
	font-size: 16px;
	line-height: 19px;
	text-transform: uppercase;
	margin-top: auto;

	@include bp(lg) {
		height: 0;
		overflow: hidden;
		transition: all $animation 0.3s;
	}
}

.aa-case-card__location {
	display: flex;
	align-items: center;
	margin-top: 35px;

	img {
		width: 16px;
		height: 16px;
		margin-right: 5px;
	}
}

// Modifiers for layout

.aa-case-cards--4 {
	.aa-case-cards__body {
		@include bp(sm) {
			grid-template-columns: repeat(2, 1fr);
		}
		@include bp(lg) {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}

.aa-case-cards--5 {
	.aa-case-cards__body {
		max-width: 1560px;
		margin-left: auto;
		margin-right: auto;

		@include bp(sm) {
			grid-template-columns: repeat(2, 1fr);
		}
		@include bp(lg) {
			grid-template-columns: repeat(5, 1fr);
		}
	}
}

.aa-case-card--overlay-primary {
	&:after {
		content: "";
		position: absolute;
		z-index: 3;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(360deg, #4E8B79 21.01%, rgba(78, 139, 121, 0) 100%);
	}
}

.aa-case-card--overlay-secondary {
	&:after {
		content: "";
		position: absolute;
		z-index: 3;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(360deg, #EC6608 21.01%, rgba(236, 102, 8, 0) 100%);
	}

	.aa-case-card__read-more {
		color: $white;
	}
}

// V2

.aa-case-cards-v2 {
	position: relative;

	.bg-graphic-bottom-left,
	.bg-graphic-top-right {
		position: absolute;
		z-index: 2;
		height: auto;
	}

	.bg-graphic-bottom-left {
		bottom: 0;
		left: 0;
	}

	.bg-graphic-top-right {
		top: 150px;
		right: 0;
	}

    .aa-inner {
		position: relative;
		z-index: 6;
        max-width: $full-site-width;
        margin-left: auto;
		margin-right: auto;
		flex-direction: column;
	}

	.aa-case-cards__body {
		max-width: $full-site-width;
		width: 100%;
		display: grid;
		grid-template-columns: 1fr;
		gap: 15px;

		@include bp(sm) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include bp(lg) {
			grid-template-columns: repeat(3, 1fr);
		}

		@include bp(xl) {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	.aa-case-card {
		display: flex;
		flex-direction: column;
		width: 100%;
		box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
		text-decoration: none;
		cursor: pointer;
		transition: all $speed $animation;

		&:hover {
			@include bp(lg) {
				.aa-case-card__read-more {
					height: auto;
				}
			}
		}
	}

	.aa-case-card.aa-card-nolink {
		cursor: initial;
	}

	.aa-case-card__header {
		min-height: 300px;
		position: relative;

		img {
			margin-left: auto;
			margin-right: auto;

		}
	}

	.aa-case-card__body {
		width: 100%;
		padding: 20px;
		transition: all $speed $animation;
		hyphens: manual;
		color: $dark;

		h1,h2,h3,h4,h5,h6 {
			color: $black;
		}

		h6 {
			margin-bottom: 20px;
		}

		p {
			margin-bottom: 10px;
		}
	}

	.aa-case-card__read-more {
		font-weight: $weight-700;
		color: $secondary;
		font-size: 16px;
		line-height: 19px;
		text-transform: uppercase;
		height: auto;
	}

	.aa-case-card__location {
		display: flex;
		align-items: center;
		margin-top: 35px;

		img {
			width: 16px;
			height: 16px;
			margin-right: 5px;
		}
	}
}

/*-----------------------------------*\
  Blog Header
\*-----------------------------------*/

.aa-blog-header {
    position: relative;
    width: 100%;

    .aa-bg-graphic {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 12;
        width: 280px;
    }

    &:after {
        content: "";
        position: absolute;
        z-index: 6;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(135.11deg, rgba(227, 233, 236, 0) 39.05%, #E3E9EC 57.38%);
        background-blend-mode: normal, luminosity;
    }
}

.aa-blog-header__inner {
    position: relative;
    z-index: 12;
    display: flex;
    flex-direction: column;
    padding-left: $padding;
    padding-right: $padding;
    padding-top: 240px;
    padding-bottom: 420px;

    @include bp(md) {
        max-width: $full-site-width;
        margin-left: auto;
        margin-right: auto;
    }

    @include bp(hg) {
        padding-left: 0;
        padding-right: 0;
    }
}

.aa-blog-header__title {
    max-width: calc(#{$full-site-width} / 12 * 7);
    font-weight: $weight-900;
}

.aa-blog-header__content {
    max-width: calc(#{$full-site-width} / 12 * 4);
}
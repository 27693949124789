/*-----------------------------------*\
    AA - Video
\*-----------------------------------*/

.aa-video {
    .aa-content-width--narrow {
        @include bp(lg) {
            max-width: calc(#{$full-site-width} / 12 * 8);
        }
    }

    .aa-content-width--normal {
        @include bp(lg) {
            max-width: calc(#{$full-site-width} / 12 * 10);
        }
    }

    .aa-content-width--wide {
        @include bp(lg) {
            max-width: calc(#{$full-site-width} / 12 * 12);
        }
    }
}

.aa-video .aa-content {
    margin-left: auto;
    margin-right: auto;
}
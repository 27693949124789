/*-----------------------------------*\
    Blog Index
\*-----------------------------------*/

@import '../components/tags';
@import '../components/pagination';
@import '../modules/blog-header';

.aa-blog--index {
    .aa-article-cards {
        position: relative;
        z-index: 12;

        .aa-inner {
            max-width: $full-site-width;
            margin-left: auto;
            margin-right: auto;
        }

        .aa-article-cards__body {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr;
            row-gap: 60px;

            @include bp(lg) {
                grid-template-columns: repeat(3, 1fr);
                max-width: 100%;
            }
        }

        .aa-article-card {
            max-width: 650px;
            overflow: visible;

            @include bp(lg) {
                max-width: 100%;
                overflow: hidden;
            }
        }
    }

    .aa-tags {
        position: relative;
        z-index: 12;
        margin-top: -260px;
    }

    .aa-form {
        margin-bottom: 45px;

        .aa-content {
            flex-direction: column;

            @include bp(lg) {
                flex-direction: row;
            }
        }
        .aa-form__column {
            margin-left: auto;
            margin-right: auto;

            &:first-of-type {
                margin-bottom: 30px;

                @include bp(lg) {
                    margin-bottom: 0;
                    padding-right: 40px;
                }
            }
        }

        .hs-submit {
            text-align: left;
        }
    }
}
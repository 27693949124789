/*-----------------------------------*\
    Info cards
\*-----------------------------------*/

.aa-info-cards {
	position: relative;

	.aa-inner {
		position: relative;
		z-index: 6;
        max-width: $full-site-width;
        margin-left: auto;
		margin-right: auto;
		flex-direction: column;
	}

	.bg-graphic-bottom-left,
	.bg-graphic-top-right {
		position: absolute;
		z-index: 2;
		height: auto;
	}
	.bg-graphic-bottom-left {
		bottom: 0;
		left: 0;
	}
	.bg-graphic-top-right {
		top: 150px;
		right: 0;
	}
}

.aa-info-cards__header {
	max-width: calc(#{$full-site-width} / 12 * 5);
	margin-left: 0;
	margin-right: auto;
	margin-bottom: 30px;

    @include bp(lg) {
        margin-bottom: 60px;
	}
}

.aa-info-cards__body {
    width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 16px;

	@include bp(sm) {
		grid-template-columns: repeat(2, 1fr);
        max-width: 100%;
		column-gap: 45px;
		row-gap: 45px;
	}

	@include bp(lg) {
		grid-template-columns: repeat(3, 1fr);
	}
}

.aa-info-card {
    display: flex;
    flex-direction: column;
	width: 100%;
	min-height: 300px;
	background-color: $white;
	box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
	padding: 30px;
	text-decoration: none;
	color: $dark;

    transition: all $speed $animation;

	@include bp(lg) {
		padding: 60px;
	}

    &:hover {
        background-color: $white;
        box-shadow: 0 4px 32px 0 rgba($black, .2);
		color: $dark;
        text-decoration: none;
	}
}

.aa-info-card.aa-card-nolink {
	transition: none;

	&:hover {
		background-color: $white;
		box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
        text-decoration: none;
    }
}

.aa-info-card__header {

    img {
		max-width: 55px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
    }
}

.aa-info-card__body {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
	padding-top: 20px;
	text-align: center;

    // background-color: $white;

    transition: all $speed $animation;
}
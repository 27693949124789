/*-----------------------------------*\
  AA - Hero Front
\*-----------------------------------*/

.aa-hero-front {
	padding-top: 160px;
	padding-bottom: 0;

	@include bp(lg) {
		padding-top: 135px;
	}

	h1,
	h1.aa-custom-title {
		font-size: 40px;
		line-height: 46px;
		background: $primary;
		background: linear-gradient(260.12deg, #EC6608 10.19%, #4E8B79 83.19%);
		background-size: 100% 100%;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

		@include bp(lg) {
			font-size: 63px;
			line-height: 63px;
			letter-spacing: -3px;
		}

		@include bp(hg) {
			font-size: 75px;
			line-height: 75px;
		}
	}

	.aa-inner {
		position: relative;
		flex-direction: column;
        padding-top: 0;
        padding-bottom: 0;

        @include bp(lg) {
			flex-direction: row;
            padding-top: 0;
        }
	}

	.aa-content {
		max-width: $full-site-width;
		margin-bottom: 30px;

		@include bp(lg) {
			display: flex;
			margin-bottom: 0;
		}
	}

	.aa-hero__header,
	.aa-hero__body {
		max-width: 100%;
		width: 100%;

		@include bp(lg) {
			max-width: calc(#{$full-site-width} / 12 * 6);
		}
	}

	.aa-hero__header {
		@include bp(lg) {
			padding-right: 40px;
			padding-top: 100px;
			padding-bottom: 100px;
		}
	}

	.aa-hero__body {
		margin-bottom: -60px;

		@include bp(lg) {
			margin-bottom: 0px;
		}

		.aa-video-container {
			height: 100%;
		}
	}

	.aa-video-container {
		width: 100%;
	}

    .aa-video-thumbnail {
        .aa-video-container {
            height: 100%;

            video {
                height: 100%;
            }
        }
	}
}
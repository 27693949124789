/*-----------------------------------*\
    Sizes
\*-----------------------------------*/

%burj-top {
    padding-top: 80px;

    @include bp(lg) {
        padding-top: 120px;
    }
}
%burj-bottom {
    padding-bottom: 80px;

    @include bp(lg) {
        padding-bottom: 120px;
    }
}
%burj-left {
    padding-left: 80px;

    @include bp(lg) {
        padding-left: 120px;
    }
}
%burj-right {
    padding-right: 80px;

    @include bp(lg) {
        padding-right: 120px;
    }
}
%burj-top-margin {
    margin-top: 80px;

    @include bp(lg) {
        margin-top: 120px;
    }
}
%burj-bottom-margin {
    margin-bottom: 80px;

    @include bp(lg) {
        margin-bottom: 120px;
    }
}
%burj-left-margin {
    margin-left: 80px;

    @include bp(lg) {
        margin-left: 120px;
    }
}
%burj-right-margin {
    margin-right: 80px;

    @include bp(lg) {
        margin-right: 120px;
    }
}

%empire-top {
    padding-top: 60px;

    @include bp(lg) {
        padding-top: 80px;
    }
    @include bp(hg) {
        padding-top: 100px;
    }
}
%empire-bottom {
    padding-bottom: 60px;

    @include bp(lg) {
        padding-bottom: 80px;
    }
    @include bp(hg) {
        padding-bottom: 100px;
    }
}
%empire-top-margin {
    margin-top: 60px;

    @include bp(lg) {
        margin-top: 80px;
    }
    @include bp(hg) {
        margin-top: 100px;
    }
}
%empire-bottom-margin {
    margin-bottom: 60px;

    @include bp(lg) {
        margin-bottom: 80px;
    }
    @include bp(hg) {
        margin-bottom: 100px;
    }
}

%eiffel-top {
    padding-top: 40px;

    @include bp(lg) {
        padding-top: 50px;
    }
    @include bp(hg) {
        padding-top: 64px;
    }
}
%eiffel-bottom {
    padding-bottom: 40px;

    @include bp(lg) {
        padding-bottom: 50px;
    }
    @include bp(hg) {
        padding-bottom: 64px;
    }
}
%eiffel-left {
    padding-left: 40px;

    @include bp(lg) {
        padding-left: 50px;
    }
    @include bp(hg) {
        padding-left: 64px;
    }
}
%eiffel-right {
    padding-right: 40px;

    @include bp(lg) {
        padding-right: 50px;
    }
    @include bp(hg) {
        padding-right: 64px;
    }
}
%eiffel-top-margin {
    margin-top: 40px;

    @include bp(lg) {
        margin-top: 50px;
    }
    @include bp(hg) {
        margin-top: 64px;
    }
}
%eiffel-bottom-margin {
    margin-bottom: 40px;

    @include bp(lg) {
        margin-bottom: 50px;
    }
    @include bp(hg) {
        margin-bottom: 64px;
    }
}
%eiffel-left-margin {
    margin-left: 40px;

    @include bp(lg) {
        margin-left: 50px;
    }
    @include bp(hg) {
        margin-left: 64px;
    }
}
%eiffel-right-margin {
    margin-right: 40px;

    @include bp(lg) {
        margin-right: 50px;
    }
    @include bp(hg) {
        margin-right: 64px;
    }
}

%liberty-top {
    padding-top: 30px;

    @include bp(hg) {
        padding-top: 40px;
    }
}
%liberty-bottom {
    padding-bottom: 30px;

    @include bp(hg) {
        padding-bottom: 40px;
    }
}
%liberty-left {
    padding-left: 30px;

    @include bp(hg) {
        padding-left: 40px;
    }
}
%liberty-right {
    padding-right: 30px;

    @include bp(hg) {
        padding-right: 40px;
    }
}
%liberty-top-margin {
    margin-top: 30px;

    @include bp(hg) {
        margin-top: 40px;
    }
}
%liberty-bottom-margin {
    margin-bottom: 30px;

    @include bp(hg) {
        margin-bottom: 40px;
    }
}
%liberty-left-margin {
    margin-left: 30px;

    @include bp(hg) {
        margin-left: 40px;
    }
}
%liberty-right-margin {
    margin-right: 30px;

    @include bp(hg) {
        margin-right: 40px;
    }
}

%londonbridge-top {
    padding-top: 10px;

    @include bp(lg) {
        padding-top: 20px;
    }
}
%londonbridge-bottom {
    padding-bottom: 10px;

    @include bp(lg) {
        padding-bottom: 20px;
    }
}
%londonbridge-right {
    padding-right: 10px;

    @include bp(lg) {
        padding-right: 20px;
    }
}
%londonbridge-left {
    padding-left: 10px;

    @include bp(lg) {
        padding-left: 20px;
    }
}
%londonbridge-top-margin {
    margin-top: 10px;

    @include bp(lg) {
        margin-top: 20px;
    }
}
%londonbridge-bottom-margin {
    margin-bottom: 10px;

    @include bp(lg) {
        margin-bottom: 20px;
    }
}
%londonbridge-right-margin {
    margin-right: 10px;

    @include bp(lg) {
        margin-right: 20px;
    }
}
%londonbridge-left-margin {
    margin-left: 10px;

    @include bp(lg) {
        margin-left: 20px;
    }
}

.burj {
    @extend %burj-top;
    @extend %burj-bottom;
}

.empire {
    @extend %empire-top;
    @extend %empire-bottom;
}

.eiffel {
    @extend %eiffel-top;
    @extend %eiffel-bottom;
}
.liberty {
    @extend %liberty-top;
    @extend %liberty-bottom;
}
.londonbridge {
    @extend %londonbridge-top;
    @extend %londonbridge-bottom;
}

/*-----------------------------------*\
    Hamburger
\*-----------------------------------*/

.aa-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    overflow-y: scroll;
    padding: 120px 20px;

    background: rgba($black,0.7);
    z-index: 99999;
    visibility: hidden;
    opacity: 0;
    transition: opacity $speed $animation;

    @include bp(md) {
        padding: 60px 30px;
    }

    img, video {
        display: block;
        max-width: 100%;
    }

    &.is-active {
        visibility: visible;
        opacity: 1;
    }
}

.aa-modal__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 250px;
    max-width: calc(#{$full-site-width} / 12 * 8);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding: 20px;

    @include bp(md) {
        min-height: 500px;
        padding: 30px;
    }

    @include bp(xl) {
        padding: 50px;
    }

    background-color: $white;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.4);
}

.aa-modal__body {
    position: relative;
    width: 100%;
}

.aa-modal__close  {
    padding: 0;
    margin: 0;
    border: 0;

    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    background-color: $secondary;

    cursor: pointer;
    z-index: 999999;
    transform: translateX(50%) translateY(-50%);
    transition: all $speed $animation;

    &:hover, &:focus {
        background-color: $primary;
    }

    &:before, &:after {
        content: '';
        flex: 0 0 auto;
        width: 1px;
        height: 20px;
        background-color: $white;
    }

    &:before {
        transform: rotate(-45deg);
        margin-right: -0.5px;
    }

    &:after {
        transform: rotate(45deg);
        margin-left: -0.5px;
    }
}

.aa-modal--video {
    .aa-modal__inner {
        width: auto;
        padding: 0;

        background-color: transparent;
    }
}

.aa-modal--fullscreen {
    img, video {
        display: block;
        width: 100%;
        max-width: $full-site-width;
        margin-left: auto;
        margin-right: auto;
    }

    .aa-modal__inner {
        position: static;
        max-width: none;
        padding: 0;

        background-color: transparent;
    }

    .aa-modal__close {
        top: 20px;
        right: 20px;

        background: none;
        border-radius: 0px;
        transform: none;

        &:before, &:after {
            display: block;
            width: 4px;
            height: 40px;
        }

        &:before {
            margin-right: -2px;
        }

        &:after {
            margin-left: -2px;
        }
    }
}

body.aa-modal-active {
    overflow-y: hidden;
}